
<template>
    <Head>
        <Meta name="robots" content="noindex, nofollow" />
    </Head>
    <slot />
</template>

<script>

export default {
    name: 'HeadlessLayout',
    inject: ['$global']
};
</script>
